//vendors
import {takeEvery, put, call} from "redux-saga/effects";

//actions
import * as actions from '../actions';
import * as types from '../action_types';

//api
import { AwsApi } from 'api/aws.api';
import {tFoundData} from "../../../types/calculate.type";


const getPriceData = (data: any) => {
  const onDemand = data['terms']['OnDemand'];
  const priceDimension = Object.keys(onDemand)[0];
  const hourlyPrice = onDemand[priceDimension]['priceDimensions'][Object.keys(onDemand[priceDimension]['priceDimensions'])[0]]['pricePerUnit']['USD'];
  return {hourlyPrice, priceDimension}
}

export function* getInstanceSaga(action: { region: string, params: Obj, type: string }) {
  try {
    const response = yield call(AwsApi.getAWS, action.region, action.params);
    const {hourlyPrice, priceDimension} = getPriceData(response)
    const data: tFoundData = {
      attributes: {vinCode: priceDimension, pricePerHour: hourlyPrice, ...response.product.attributes},
      price: hourlyPrice
    }
    yield put(actions.successGetEC2(data));
  } catch (e) {
    yield put(actions.failRequest());
  }
}

export function* getEC2StorageSaga(action: { region: string, type: string }) {
  try {
    const response = yield call(AwsApi.getEC2Storage, action.region);
    const {hourlyPrice, priceDimension} = getPriceData(response)
    const data: tFoundData = {
      attributes: {vinCode: priceDimension, pricePerHour: hourlyPrice, ...response.product.attributes},
      price: hourlyPrice
    }
    yield put(actions.successGetEC2Storage(data));
  } catch (e) {
    yield put(actions.failRequest());
  }
}

export function* getRDSSaga(action: { region: string, params: Obj, type: string }) {
  try {
    const response = yield call(AwsApi.getRDS, action.region, action.params);
    const {hourlyPrice, priceDimension} = getPriceData(response)
    const data: tFoundData = {
      attributes: {vinCode: priceDimension, pricePerHour: hourlyPrice, ...response.product.attributes},
      price: hourlyPrice
    }
    yield put(actions.successGetRDS(data));
  } catch (e) {
    yield put(actions.failRequest());
  }
}

export function* getRegionsSaga(action: { params: Obj, type: string }) {
  try {
    const response: tOption[] = yield call(AwsApi.getIRegions, action.params);
    yield put(actions.successGetRegions(response));
  } catch (e) {
    yield put(actions.failRequest());
  }
}

export function* rootMainSaga() {
  yield takeEvery(types.GET_EC2_REQUESTED, getInstanceSaga);
  yield takeEvery(types.GET_EC2_STORAGE_REQUESTED, getEC2StorageSaga);
  yield takeEvery(types.GET_RDS_REQUESTED, getRDSSaga);
  yield takeEvery(types.GET_REGIONS_REQUESTED, getRegionsSaga);
}
