export const databaseTypes: tOption[] = [
  {value: "oracle", label: "Oracle"},
  // {value: "postgresql", label: "PostgreSQL"},
  {value: "sqlserver", label: "SQL Server"},
]

export const oracleInstanceTypes: tOption[] = [
  {value: "db.m5.large", label: "m5.large"},
  {value: "db.m5.xlarge", label: "m5.xlarge"},
  {value: "db.m5.2xlarge", label: "m5.2xlarge"},
  {value: "db.m5.4xlarge", label: "m5.4xlarge"},
  {value: "db.r6i.large", label: "r6i.large"},
  {value: "db.r6i.xlarge", label: "r6i.xlarge"},
  {value: "db.r6i.2xlarge", label: "r6i.2xlarge"},
  {value: "db.r6i.4xlarge", label: "r6i.4xlarge"},
  {value: "db.t3.large", label: "t3.large"},
  {value: "db.t3.xlarge", label: "t3.xlarge"},
  {value: "db.t3.2xlarge", label: "t3.2xlarge"},
]

export const mssqlInstanceTypes: tOption[] = [
  {value: "db.m5.xlarge", label: "m5.xlarge"},
  {value: "db.m5.2xlarge", label: "m5.2xlarge"},
  {value: "db.m5.4xlarge", label: "m5.4xlarge"},
  {value: "db.r6i.xlarge", label: "r6i.xlarge"},
  {value: "db.r6i.2xlarge", label: "r6i.2xlarge"},
  {value: "db.r6i.4xlarge", label: "r6i.4xlarge"},
  {value: "db.t3.xlarge", label: "t3.xlarge"},
  {value: "db.t3.2xlarge", label: "t3.2xlarge"},
]