//vendors
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDropdown} from "hooks/dropdown.hook";

//layouts
import {VBox, HBox} from '@acc/layout';
import {useInput} from "hooks/input.hook";
import {SimpleTextField, Dropdown} from "@acc/form";
import {tFoundData, tInstance} from "types/calculate.type";
import {Loader} from "modules/common/loader";
import {Header} from "./header/header";
import {Tooltip} from '@acc/tooltip';
//styles
import "../app.scss";
import {GreenArrowIcon} from "assets/images/icons/green_arrow";
import {RedArrowIcon} from "assets/images/icons/red_arrow";
import classNames from "classnames";
import {databaseTypes, mssqlInstanceTypes, oracleInstanceTypes} from "../constants";
import {KeyValueList} from "./key_value_list";

type Props = {
	ec2?: tFoundData
	rds?: tFoundData
	ec2Storage?: tFoundData
	hasError: boolean,
	calculationInProgress: boolean,
	calculationIsAvailable: boolean,
	regions: tOption[]
	regionsInProgress: boolean,
	fullInstanceType: tInstance,
	savedSettings: Obj,
	requestGetEC2: (region: string, params: Obj) => void,
	requestGetRDS: (region: string, params: Obj) => void,
	requestGetEC2Storage: (region: string) => void,
	requestGetRegions: (params: Obj) => void,
	saveSettings: (data: Obj) => void
} & any;



export const App = (props: Props) => {
	const region = useDropdown(null)
	const awsType = useDropdown(databaseTypes[0])
	const instanceType = useDropdown(oracleInstanceTypes[1])
	const storageGB = useInput(2000)
	const numberOfStagingEnvironments = useInput(5)

	const onCalculate = useCallback(() => {
		const instanceFilters = {
			instanceType: instanceType.option.label,
		}

		const instanceRDSFilters = {
			instanceType: instanceType.option.value,
			awsType: awsType.option.label,
		}

		props.requestGetEC2(region.option?.label, instanceFilters)
		props.requestGetRDS(region.option?.label, instanceRDSFilters)
		props.requestGetEC2Storage(region.option?.label)

		const settings = {
			awsType: awsType?.option?.label,
			storageGB: storageGB?.value,
			numberOfStagingEnvironments: numberOfStagingEnvironments?.value,
		}

		props.saveSettings(settings)
	}, [region, awsType, instanceType, storageGB, numberOfStagingEnvironments])

	useEffect(() => {
		props.requestGetRegions({})
	}, [])

	useEffect(() => {
		if (props.regions && !region.option) {
			region.setOption(props.regions.find(region => region.label === "us-east-1"))
		}
	}, [props.regions])

	useEffect(() => {
		const instanceTypes = awsType?.option?.value === "sqlserver" ? mssqlInstanceTypes : oracleInstanceTypes
		const item = instanceTypes.find((item) => item?.value === instanceType.option?.value)
		instanceType.setOption(!!item ? item : instanceTypes[0])
	}, [awsType?.option, mssqlInstanceTypes, oracleInstanceTypes, instanceType])

	const giSize = useMemo(() => {
		return +props.savedSettings?.storageGB * 0.6
	}, [props.savedSettings?.storageGB])

	const rdsSize = useMemo(() => {
		return +props.savedSettings?.storageGB + props.savedSettings?.numberOfStagingEnvironments * +props.savedSettings?.storageGB
	}, [props.savedSettings?.storageGB, props.savedSettings])

	const ec2Size = useMemo(() => {
		return giSize + props.savedSettings?.numberOfStagingEnvironments * giSize * 0.1
	}, [props.savedSettings?.numberOfStagingEnvironments, props.savedSettings, giSize])

	const instanceSettings = useMemo(() => {
		return <>
			{props.ec2?.attributes?.instanceType} / {props.ec2?.attributes?.vcpu} CPU / {props.ec2?.attributes?.memory?.split(" ")[0]} Gb
		</>
	}, [props.fullInstanceType])

	const rdsYearPrice = useMemo(() => {
		const instancePrice = +props.rds?.price * 730
		const storagePrice = +rdsSize * props.ec2Storage?.price

		return {
			instancePrice,
			storagePrice,
			allPrice: instancePrice + storagePrice,
		}
	}, [props.rds?.price, rdsSize, props.ec2Storage?.price])

	const ec2YearPrice = useMemo(() => {
		const instancePrice = +props.ec2?.price * 730
		const storagePrice = +ec2Size * props.ec2Storage?.price
		return {
			instancePrice,
			storagePrice,
			allPrice: instancePrice + storagePrice,
		}
	}, [props.ec2?.price, ec2Size, props.ec2Storage?.price])

	const calculatePercentageDifference = (price1: number, price2: number) => {
		return !price2 ? 0 : 100 - price1 * 100 / price2
	}

	const saveEC2PercentPerYear = useMemo(() => {
		const instancePercentageDifference = calculatePercentageDifference(ec2YearPrice.instancePrice, rdsYearPrice.instancePrice)
		const storagePercentageDifference = calculatePercentageDifference(ec2YearPrice.storagePrice, rdsYearPrice.storagePrice)
		const totalPercentageDifference = calculatePercentageDifference(ec2YearPrice.allPrice, rdsYearPrice.allPrice)

		return {
			instancePercent: instancePercentageDifference,
			storagePercent: storagePercentageDifference,
			totalPercent: totalPercentageDifference,
		}
	}, [rdsYearPrice.storagePrice, ec2YearPrice.storagePrice, ec2YearPrice.instancePrice, rdsYearPrice.instancePrice])
		return (
		<VBox size={"auto"} className="body">
			<div className="header">
				<Header />
			</div>
			<div className="container">
				<div className="container-head">
					<div className="container-head__content">
						<div className="container-head__content-left">
							ROI Calculator for AWS RDS
						</div>
						<div className="container-head__content-right">
							Designed to help customers explore the possibility of cutting the running costs of their testing
							infrastructure
							on AWS RDS.
						</div>
					</div>
				</div>
				<div className="container-body">
					<div className="container-body__left">
						<p className="container-body-title">Parameters</p>
						<Dropdown
							onChange={region.setOption}
							options={props.regions}
							value={region.option}
							required={false}
							classNameWrap="container-body__left-field"
							label="Region:"
							withBorder={false}
							isSearchable={true}
							menuPortalTarget={document.body}
							placeholder="Select region..."/>

						<Dropdown
							onChange={awsType.setOption}
							options={databaseTypes}
							value={awsType.option}
							required={false}
							classNameWrap="container-body__left-field"
							label="AWS RDS Type:"
							withBorder={false}
							isSearchable={true}
							menuPortalTarget={document.body}
							placeholder="Select AWS RDS type..."/>

						<Dropdown
							onChange={instanceType.setOption}
							options={awsType?.option?.value === "sqlserver" ? mssqlInstanceTypes : oracleInstanceTypes}
							value={instanceType.option}
							required={false}
							classNameWrap="container-body__left-field"
							label="Instance type:"
							withBorder={false}
							isSearchable={true}
							menuPortalTarget={document.body}
							placeholder="Select instance type..."/>

						<SimpleTextField
							label="Storage, Gb:"
							value={storageGB.value}
							onChange={storageGB.setValue}
							onBlur={() => storageGB.setTouched(true)}
							touched={storageGB.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={numberOfStagingEnvironments.error}
						/>

						<SimpleTextField
							label="Number of staging environments:"
							value={numberOfStagingEnvironments.value}
							onChange={numberOfStagingEnvironments.setValue}
							onBlur={() => numberOfStagingEnvironments.setTouched(true)}
							touched={numberOfStagingEnvironments.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={numberOfStagingEnvironments.error}
						/>

							<p className="title smaller">
								Costs associated with the virtualization service are not included*
							</p>

						<button
							disabled={props.calculationInProgress || props.regionsInProgress}
							className="header-content__button"
							onClick={onCalculate}
						>
							Calculate
						</button>

					</div>
					<div className="container-body__divider">
					</div>
					{props.hasError &&

						<div className="container-body__right  error">
							<p className="container-body-title">Error</p>
							<div className="container-body__right-top">
								<div className="config">
									<div className="config-info">
										<div className="config-info-column">
											<p className="config-info-row__title">Currently experiencing disruptions due to AWS API issues.</p>
											<p className="config-info-row__title">For immediate assistance, please contact <a
												className="email"
												href="mailto:support@accelario.com?subject=Support%20request%20-%20Data%20Masking"
												target="_blank"
												rel="noopener noreferrer"
											>
												support@accelario.com.
											</a>
											</p>
											<p className="config-info-row__title">
												Apologies for the inconvenience. We're working to resolve this quickly. Thank you for your
												patience.
											</p>

										</div>
									</div>
								</div>
							</div>
						</div>
					}

					{(props.calculationInProgress && !props.hasError) &&
						<HBox size="auto" height="337px" justify="center" align="end" className="container-body__right">
							<Loader size="large"/>
						</HBox>
					}
					{props.calculationIsAvailable &&
						<div className="container-body__right">
							<p className="container-body-title">Analysis</p>
							<div className="container-body__right-top">
								<div className="config">
									<p className="config-title">
										My configuration
									</p>
									<div className="config-info">
										<div className="config-info-row">
											<p className="config-info-row__title">
												Size of production database:
											</p>
											<p className="config-info-row__value">
												{(props.savedSettings?.storageGB / 1000).toFixed(1)} Tb
											</p>
										</div>
										<div className="config-info-row">
											<p className="config-info-row__title">
												Instance type:
											</p>
											<p className="config-info-row__value">
												{instanceSettings}
											</p>
										</div>
										<div className="config-info-row">
											<p className="config-info-row__title">
												Total size of staging environments volume:
											</p>
											<p className="config-info-row__value">
												{(rdsSize / 1000).toFixed(1)} Tb
											</p>
										</div>
										<div className="config-info-row">
											<p className="config-info-row__title">
												Total size of virtualized staging environments volume:
											</p>
											<p className="config-info-row__value">
												{(ec2Size / 1000).toFixed(1)} Tb
											</p>
										</div>
										<div className="divider"/>
										<div className="config-percent">
											<div className="config-percent__item">
												<p className="config-percent__item-title">Instance savings</p>
												<p className="config-percent__item-value">{saveEC2PercentPerYear?.instancePercent?.toFixed(0)}<span>%</span></p>
											</div>
											<div className="config-percent__item">
												<p className="config-percent__item-title">Storage savings</p>
												<p className="config-percent__item-value">{saveEC2PercentPerYear?.storagePercent?.toFixed(0)}<span>%</span></p>
											</div>
											<div className="config-percent__item">
												<p className="config-percent__item-title">Total savings</p>
												<p className="config-percent__item-value">{saveEC2PercentPerYear?.totalPercent?.toFixed(0)}<span>%</span></p>
											</div>
										</div>
									</div>
								</div>
								<div className="costs">
									<div className="costs-bottom">
										<p className="costs-bottom__title">Savings (Monthly costs)</p>
										<p className="costs-bottom__title__small">Instance</p>
										<p
											className="costs-bottom__value__small">{(rdsYearPrice.instancePrice - ec2YearPrice.instancePrice)?.toFixed(0)}
											<span> USD</span></p>
										<p className="costs-bottom__title__small">Storage</p>
										<p
											className="costs-bottom__value__small">{(rdsYearPrice.storagePrice - ec2YearPrice.storagePrice)?.toFixed(0)}
											<span> USD</span></p>
										<p className="costs-bottom__title__small">Total</p>
										<p className="costs-bottom__value">{(rdsYearPrice.allPrice - ec2YearPrice.allPrice)?.toFixed(0)}
											<span> USD</span></p>
									</div>
								</div>
							</div>
							<div className="container-body__right-bottom">
								<div className="container-body__right-bottom__title">
									<p className="title">Calculate details (Monthly costs, USD)</p>
								</div>
								<table>
									<thead>
									<th>Name</th>
									<th>Instance cost</th>
									<th>Storage cost</th>
									<th>Total</th>
									<th className="phone-table"></th>
									<th className="phone-table">Name</th>
									<th className="phone-table">Instance cost</th>
									<th className="phone-table">Storage cost</th>
									<th className="phone-table">Total</th>
									</thead>
									<tbody>
									<tr>
										<td>AWS EC2 with Accelario Database Virtualization</td>

										<td className="price">
											<div className="noWrap">
												<Tooltip message={<KeyValueList dataObject={props.ec2.attributes}/>} placement={"top-start"} trigger={"click"} delayShow={0}>
													<div className="pointer">{ec2YearPrice?.instancePrice.toFixed(0)}</div>
												</Tooltip>
												{saveEC2PercentPerYear?.instancePercent !== 0 &&
													<span className={classNames({"red": saveEC2PercentPerYear?.instancePercent <= 0})}>
														{saveEC2PercentPerYear?.instancePercent > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
														{saveEC2PercentPerYear?.instancePercent?.toFixed(0)}%
													</span>
												}
											</div>
										</td>

										<td className="price">
											<div className="noWrap">
												<Tooltip message={<KeyValueList dataObject={props.ec2Storage.attributes}/>} placement={"top-start"} trigger={"click"} delayShow={0}>
													<div className="pointer">{ec2YearPrice?.storagePrice.toFixed(0)}</div>
												</Tooltip>
												{saveEC2PercentPerYear?.storagePercent !== 0 &&
													<span className={classNames({"red": saveEC2PercentPerYear?.storagePercent <= 0})}>
														{saveEC2PercentPerYear?.storagePercent > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
														{saveEC2PercentPerYear?.storagePercent?.toFixed(0)}%
													</span>
												}
											</div>
										</td>

										<td className="price">
											{ec2YearPrice?.allPrice?.toFixed(0)}
											{saveEC2PercentPerYear?.totalPercent !== 0 &&
												<span className={classNames({"red": saveEC2PercentPerYear?.totalPercent <= 0})}>
												{saveEC2PercentPerYear?.totalPercent > 0 ? <GreenArrowIcon/> : <RedArrowIcon/>}
													{saveEC2PercentPerYear?.totalPercent?.toFixed(0)}%
											</span>
											}
										</td>
									</tr>
									<tr>
										<td>AWS RDS for {props.savedSettings?.awsType}</td>
										<td className="price">
											<Tooltip message={<KeyValueList dataObject={props.rds.attributes}/>} placement={"top-start"} trigger={"click"} delayShow={0}>
												<div className="pointer">{rdsYearPrice.instancePrice?.toFixed(0)}</div>
											</Tooltip>
										</td>
										<td className="price">
											<Tooltip message={<KeyValueList dataObject={props.ec2Storage.attributes}/>} placement={"top-start"} trigger={"click"} delayShow={0}>
												<div className="pointer">{rdsYearPrice.storagePrice?.toFixed(0)}</div>
											</Tooltip>
										</td>
										<td className="price">{(rdsYearPrice.allPrice).toFixed(0)}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					}

				</div>
			</div>
		</VBox>
		)
}