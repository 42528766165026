import * as types from '../action_types';
import {tFoundData} from "types/calculate.type";

export const requestGetEC2 = (region: string, params: Obj) => ({
  type: types.GET_EC2_REQUESTED,
  region,
  params
});
export const successGetEC2 = (data: tFoundData) => ({
  type: types.GET_EC2_SUCCEEDED,
  data
});

export const requestGetRDS = (region: string, params: Obj) => ({
  type: types.GET_RDS_REQUESTED,
  region,
  params
});
export const successGetRDS = (data: tFoundData) => ({
  type: types.GET_RDS_SUCCEEDED,
  data
});

export const requestGetEC2Storage = (region: string) => ({
  type: types.GET_EC2_STORAGE_REQUESTED,
  region
});
export const successGetEC2Storage = (data: tFoundData) => ({
  type: types.GET_EC2_STORAGE_SUCCEEDED,
  data
});

export const requestGetRegions = (params: any) => ({
  type: types.GET_REGIONS_REQUESTED,
  params
});
export const successGetRegions = (data: tOption[]) => ({
  type: types.GET_REGIONS_SUCCEEDED,
  data
});

export const failRequest = () => ({
  type: types.REQUEST_FAILED
});


export const saveSettings = (data: Obj) => ({
  type: types.SAVE_SETTINGS,
  data
});

