//vendors
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//components
import { App } from '../components/app';
//selectors
import {
  getEC2,
  getRDS,
  getRegions,
  isHasError,
  getEC2Storage,
  getSavedSettings,
  getFullInstanceType,
  getRegionsInProgress,
  getCalculationInProgress,
  getCalculationIsAvailable
} from "../selectors/app.selector";
//action
import {
  requestGetEC2,
  requestGetEC2Storage,
  requestGetRDS,
  requestGetRegions,
  saveSettings,
} from "../actions";

const mapStateToProps = (state: any) => ({
  ec2: getEC2(state),
  rds: getRDS(state),
  ec2Storage: getEC2Storage(state),
  calculationInProgress: getCalculationInProgress(state),
  calculationIsAvailable: getCalculationIsAvailable(state),
  regions: getRegions(state),
  regionsInProgress: getRegionsInProgress(state),
  fullInstanceType: getFullInstanceType(state),
  savedSettings: getSavedSettings(state),
  hasError: isHasError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  requestGetEC2: bindActionCreators(requestGetEC2, dispatch),
  requestGetRDS: bindActionCreators(requestGetRDS, dispatch),
  requestGetEC2Storage: bindActionCreators(requestGetEC2Storage, dispatch),
  requestGetRegions: bindActionCreators(requestGetRegions, dispatch),
  saveSettings: bindActionCreators(saveSettings, dispatch),
});

export const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
