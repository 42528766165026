import {useState} from "react";

export type tDropdownHook = {
  option: tOption | null,
  touched: boolean,
  error: string | undefined
  setOption: (value: tOption | null) => void,
  setError: (error: string | undefined) => void,
  setTouched: (value: boolean) => void
}

export function useDropdown(initialValue): tDropdownHook {
  const [option, setOption] = useState<tOption | null>(initialValue);
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  
  return {
    option, setOption, touched, error, setTouched, setError
  }
}
