//vendors
import { apiResponseHandler } from "./api_response_handler";
import AWS from "aws-sdk";
import {getAWSPrice} from "./aws_integration";

AWS.config.update({
  accessKeyId: 'AKIAZ43Z7V5MLJPK4G44',
  secretAccessKey: '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',
});

const getPrice = (data: any) => {
  const onDemand = data['terms']['OnDemand'];
  const priceDimension = Object.keys(onDemand)[0];
  const hourlyPrice = onDemand[priceDimension]['priceDimensions'][Object.keys(onDemand[priceDimension]['priceDimensions'])[0]]['pricePerUnit']['USD'];
  return hourlyPrice
}

export const AwsApi = {
  getAWS: async (region: string, params: any) => {
    const client = new AWS.Pricing({region: "us-east-1"});

    const filters = {
      ServiceCode: 'AmazonEC2',
      Filters: [
        { Type: 'TERM_MATCH', Field: 'instanceType', Value: params.instanceType },
        { Type: 'TERM_MATCH', Field: 'operatingSystem', Value: 'Linux' },
        { Type: 'TERM_MATCH', Field: 'tenancy', Value: 'Shared' },
        { Type: 'TERM_MATCH', Field: 'termType', Value: 'OnDemand' },
        {Type: "TERM_MATCH", Field: "regionCode", Value: region },
        { Type: "TERM_MATCH", Field: "preInstalledSw", Value: "NA" },

      ],
      MaxResults: 100
    }

    const checkMatch = (item) => {
      return Number(getPrice(item)) > 0;
    };

    return await getAWSPrice(
      {client, filters, checkMatch}
    )
  },
  getRDS: async(region: string, params: any) => {
    const client = new AWS.Pricing({region: "us-east-1"});
    const filters = {
      ServiceCode: 'AmazonRDS',
      Filters: [
        { Type: 'TERM_MATCH', Field: 'instanceType', Value: params.instanceType },
        { Type: 'TERM_MATCH', Field: 'databaseEngine', Value: params.awsType },
        { Type: 'TERM_MATCH', Field: 'termType', Value: 'OnDemand' },
        { Type: "TERM_MATCH", Field: "regionCode", Value: region },
        { Type: "TERM_MATCH", Field: "databaseEdition", Value: "Enterprise" },
        { Type: "TERM_MATCH", Field: "deploymentOption", Value: "Single-AZ" },
        { Type: "TERM_MATCH", Field: "engineMediaType", Value: "Customer-provided" },
      ],
      MaxResults: 100
    }
    switch (params.awsType) {
      case "Oracle":
        filters.Filters.push({
          Type: 'TERM_MATCH',
          Field: 'licenseModel',
          Value: 'Bring your own license'
        })
        break;
      case "SQL Server":
        filters.Filters.push({
        Type: 'TERM_MATCH',
        Field: 'licenseModel',
        Value: 'NA'
      })
        break;
    }

    const checkMatch = (item) => {
      return Number(getPrice(item)) > 0;
    };

    return await getAWSPrice(
      {client, filters, checkMatch}
    )
  },
  getEC2Storage: async (region: string) => {
    const filters = {
      ServiceCode: 'AmazonEC2',
      Filters: [
        { Type: 'TERM_MATCH', Field: 'productFamily', Value: 'Storage' },
        { Type: "TERM_MATCH", Field: "regionCode", Value: region },
        { Type: "TERM_MATCH", Field: "storageMedia", Value: "SSD-backed" },
      ],
      MaxResults: 100
    }
    const client = new AWS.Pricing({region: "us-east-1"});
    const checkMatch = (item) => {
      return Number(getPrice(item)) > 0;
    };

    return await getAWSPrice(
      {client, filters, checkMatch}
    )
  },
  getIRegions: (params: any) => {
    try {
      return new Promise((resolve, reject) => {
        const ec2 = new AWS.EC2({region: 'us-east-1'});
        ec2.describeRegions(params, (err, data) => {
          try {
            resolve(data.Regions.map(region => ({
              label: region.RegionName,
              value: region.Endpoint,
            })))
          } catch {
            reject(err)
          }
        });
      });
    } catch (e) {
      apiResponseHandler(e)
    }
  }
};
