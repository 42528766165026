const camelCaseToNormalText = (str: string) =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (char) => char.toUpperCase());

export const KeyValueList = ({ dataObject }) => {
  const items = Object.keys(dataObject).map((key) => (
    <li key={key} style={{textAlign: "left"}}>
      {camelCaseToNormalText(key)}: {!!dataObject[key] ? dataObject[key] : "None"}
    </li>
  ));

  return <ul style={{paddingLeft: "14px"}}>{items}</ul>;
};