export const GET_EC2_REQUESTED = 'main/GET_EC2_REQUESTED';
export const GET_EC2_SUCCEEDED = 'main/GET_EC2_SUCCEEDED';
export const REQUEST_FAILED = 'main/REQUEST_FAILED';

export const GET_RDS_REQUESTED = 'main/GET_RDS_REQUESTED';
export const GET_RDS_SUCCEEDED = 'main/GET_RDS_SUCCEEDED';

export const GET_EC2_STORAGE_REQUESTED = 'main/GET_EC2_STORAGE_REQUESTED';
export const GET_EC2_STORAGE_SUCCEEDED = 'main/GET_EC2_STORAGE_SUCCEEDED';

export const GET_REGIONS_REQUESTED = 'main/GET_REGIONS_REQUESTED';
export const GET_REGIONS_SUCCEEDED = 'main/GET_REGIONS_SUCCEEDED';

export const SAVE_SETTINGS = 'main/SAVE_SETTINGS';
