export const getAWSPrice = async ({client, filters, checkMatch}) => {
  let nextToken = null;
  let matchedProduct = null;

  const fetchProduct = async (nextToken: string | null) => {
    return new Promise((resolve, reject) => {
      client.getProducts({ ...filters, NextToken: nextToken }, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  try {
    do {
      if (!!matchedProduct) {
        break;
      }

      const data: any = await fetchProduct(nextToken);
      for (const item of data.PriceList) {
        if (checkMatch(item)) {
          matchedProduct = item;
        }
      }

      nextToken = data.NextToken;
    } while (nextToken);
  } catch (e) {
    throw e;
  }

  return matchedProduct;
};