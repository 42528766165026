import { createSelector } from 'reselect';

export const getEC2 = createSelector(
  [(state: any) => state.app],
  (app) => app.get("ec2Data")
);

export const getRDS = createSelector(
  [(state: any) => state.app],
  (app) => app.get("rdsData")
);

export const getCalculationInProgress = createSelector(
  [(state: any) => state.app],
  (app) => app.get("ec2DataInProgress")
    || app.get("rdsDataInProgress")
    || app.get("ec2SStorageInProgress")
);

export const getCalculationIsAvailable = createSelector(
  [(state: any) => state.app],
  (app) => !!app.get("ec2Data")
    && !!app.get("rdsData")
    && !!app.get("ec2Storage")
);


export const getEC2Storage = createSelector(
  [(state: any) => state.app],
  (app) => app.get("ec2Storage")
);

export const getRegions = createSelector(
  [(state: any) => state.app],
  (app) => app.get("regions")
);

export const getRegionsInProgress = createSelector(
  [(state: any) => state.app],
  (app) => app.get("regionsInProgress")
);

export const getSavedSettings = createSelector(
  [(state: any) => state.app],
  (app) => app.get("savedSettings")
);
export const isHasError = createSelector(
  [(state: any) => state.app],
  (app) => app.get("hasError")
);

export const getFullInstanceType = createSelector(
  [(state: any) => state.app],
  (app) => {
    return {
      instanceType: app.get("ec2Data")?.instanceType,
      memory: app.get("ec2Data")?.memory,
      vcpu: app.get("ec2Data")?.vcpu
    }
  }
);
