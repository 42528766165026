//action types
import * as types from '../action_types';
import { fromJS } from "immutable";

export const initialState = fromJS({
  ec2Data: undefined,
  ec2DataInProgress: false,

  rdsData: undefined,
  rdsDataInProgress: false,

  ec2Storage: undefined,
  ec2SStorageInProgress: false,

  regions: [],
  regionsInProgress: false,

  savedSettings: undefined,
  hasError: false,
});

export const appReducer = (state: any = initialState, action: any ) => {
  switch (action.type) {
    case types.GET_EC2_REQUESTED:
      return state
        .set("ec2DataInProgress", true)
        .set("hasError", false)
        .set("ec2Data", null);
    case types.GET_EC2_SUCCEEDED:
      return state
        .set("ec2DataInProgress", false)
        .set("ec2Data", action.data)

    case types.GET_RDS_REQUESTED:
      return state
        .set("rdsDataInProgress", true)
        .set("hasError", false)
        .set("rdsData", null);
    case types.GET_RDS_SUCCEEDED:
      return state
        .set("rdsDataInProgress", false)
        .set("rdsData", action.data)

    case types.GET_EC2_STORAGE_REQUESTED:
      return state
        .set("ec2SStorageInProgress", true)
        .set("hasError", false)
        .set("ec2Storage", null);
    case types.GET_EC2_STORAGE_SUCCEEDED:
      return state
        .set("ec2SStorageInProgress", false)
        .set("ec2Storage", action.data);


    case types.GET_REGIONS_REQUESTED:
      return state.set("regionsInProgress", true);
    case types.GET_REGIONS_SUCCEEDED:
      return state
        .set("regionsInProgress", false)
        .set("regions", action.data);

    case types.REQUEST_FAILED:
      return state
        .set("hasError", true)
        .set("ec2DataInProgress", false)
        .set("ec2Data", null)
        .set("rdsDataInProgress", false)
        .set("rdsData", null)
        .set("regionsInProgress", false);

    case types.SAVE_SETTINGS:
      return state.set("savedSettings", action.data);

    default:
      return state
  }
};
